import {
  Button,
  Card,
  CardSection,
  Group,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import React, { useState } from "react";
import { AddColumn } from "./AddColumn";
import { Chat } from "./Chat";
import { Column } from "./Column";
import classes from "./Table.module.css";
import { groupBySourceAndTable } from "./utils";

import { fetchAPIWithToken, useClient } from "@hooks/use-client";
import { notifications } from "@mantine/notifications";
import { GoldViewSupabaseSchema } from "@mm/shared/schemas/gold";
import type { Report } from "@mm/shared/schemas/reports";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BiAnalyse, BiSpreadsheet } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";

const goldViewResultParser = z.object({
  goldViewId: z.number(),
});

const useFinalizeView = (reportId: number) => {
  return useMutation({
    mutationFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/reports/${reportId}/generate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Unable to generate the data for this specific report`);
      }

      return goldViewResultParser.parse(await response.json());
    },
    onError: () => {
      notifications.show({
        title: "Unable to create view",
        message: "We were not able to create the final view for this report.",
        color: "red",
      });
    },
  });
};

const useGoldView = (reportId: number) => {
  const { supabase } = useClient();

  return useQuery({
    queryKey: ["goldView", { reportId: reportId }],
    queryFn: async () => {
      const { data: goldView } = await supabase
        .from("gold_views")
        .select()
        .eq("report_id", reportId)
        .order("created_at", { ascending: false })
        .limit(1)
        .maybeSingle()
        .throwOnError();

      return goldView ? GoldViewSupabaseSchema.parse(goldView) : null;
    },
  });
};

const Layout: React.FC<{
  children: React.ReactNode;
  bottomSection?: React.ReactNode;
}> = ({ children, bottomSection }) => {
  return (
    <Stack flex={1} style={{ overflow: "hidden" }} gap={"xs"}>
      <ScrollArea.Autosize
        flex={1}
        offsetScrollbars
        scrollbars="x"
        styles={{
          root: { display: "flex", flex: 1 },
          viewport: { display: "flex", flex: 1 },
        }}
        classNames={classes}
      >
        <Group
          flex={1}
          align="stretch"
          wrap="nowrap"
          style={{ scrollBehavior: "smooth" }}
        >
          {children}
        </Group>
      </ScrollArea.Autosize>
      {bottomSection}
    </Stack>
  );
};

export const Table: React.FC<{ report: Report }> = ({ report }) => {
  const navigate = useNavigate();
  const [chatOpened, setChatOpened] = useState(true);
  useHotkeys([["mod+K", () => setChatOpened((opened) => !opened)]]);

  const { mutate: finalize, isPending: isCreatingReport } = useFinalizeView(
    report.report.id,
  );
  const { data: goldView } = useGoldView(report.report.id);
  const generateLabel = goldView ? "Regenerate" : "Generate";

  return (
    <Layout
      bottomSection={
        <Group justify="space-between">
          <Text p={"sm"} size="xs">
            {chatOpened ? "Hide " : "Open companion "}
            <Text span c={"dimmed"}>
              ⌘ K
            </Text>
          </Text>
          {/* Same margin as the header of <Page> */}
          <Group gap={"xs"} pr={"lg"}>
            <Text c={"dimmed"} size="xs">
              {report.columns.length}{" "}
              {report.columns.length > 1 ? `columns` : `column`}
            </Text>
            <Button
              size="xs"
              disabled={report.columns.length == 0}
              variant={goldView ? "light" : "filled"}
              loading={isCreatingReport}
              leftSection={<BiAnalyse size={14} />}
              onClick={() =>
                finalize(undefined, {
                  onSuccess: (result) => {
                    navigate(`/insight/${result.goldViewId}`);
                  },
                })
              }
            >
              {generateLabel} results
            </Button>
            {goldView && (
              <Button
                size="xs"
                component={NavLink}
                to={`/insight/${goldView.id}`}
                leftSection={<BiSpreadsheet size={14} />}
              >
                View latest insights
              </Button>
            )}
          </Group>
        </Group>
      }
    >
      <Chat opened={chatOpened} setOpened={setChatOpened} />
      {Object.entries(
        groupBySourceAndTable(report.columns.filter(({ visible }) => visible)),
      )
        /* New, transformed, columns first ("null" source) */
        .sort(([sourceName]) => (sourceName === "null" ? 1 : -1))
        .map(([sourceName, tables]) => (
          <Card key={sourceName} radius={"md"}>
            <CardSection
              flex={1}
              display={"flex"}
              px={"md"}
              py={"xs"}
              style={{ overflow: "hidden" }}
            >
              <Group flex={1} align="stretch" wrap="nowrap">
                {Object.entries(tables).map(([displayTableName, columns]) => (
                  <Card key={displayTableName}>
                    <CardSection py={4} mb={"xs"}>
                      {displayTableName === "null" ? (
                        <Text>&nbsp;</Text>
                      ) : (
                        <Text fw={"bold"} style={{ whiteSpace: "nowrap" }}>
                          {displayTableName}
                        </Text>
                      )}
                    </CardSection>
                    <CardSection
                      flex={1}
                      display={"flex"}
                      style={{ overflow: "hidden" }}
                    >
                      <Group flex={1} align="stretch" wrap="nowrap">
                        {columns
                          .filter((column) => column.visible)
                          .map((column) => (
                            <Column key={column.name} column={column} />
                          ))}
                      </Group>
                    </CardSection>
                  </Card>
                ))}
              </Group>
            </CardSection>
          </Card>
        ))}
      <AddColumn report={report} />
    </Layout>
  );
};
