import {
  ActionIcon,
  Badge,
  Box,
  Group,
  Image,
  Stack,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import {
  BiBulb,
  BiCheckbox,
  BiPlus,
  BiSearch,
  BiTrendingUp,
} from "react-icons/bi";
import classes from "./NavbarSearch.module.css";
import { UserButton } from "./UserButton";

import YourProductLogo from "@images/trebel-logo.png";
import { useNavigate } from "react-router-dom";

const links = [
  { icon: BiTrendingUp, label: "Artists Activities", notifications: 3 },
  { icon: BiCheckbox, label: "Ads Campaigns Tasks", notifications: 4 },
  { icon: BiBulb, label: "Create insights" },
];

const collections = [
  { emoji: "🏠", label: "Home" },
  { emoji: "🎵", label: "Artists" },
  { emoji: "📊", label: "Ads" },
  { emoji: "🎧", label: "Tracks" },
  { emoji: "🌎", label: "Streams" },
  { emoji: "💡", label: "Reports & Insights" },
];

export function NavbarSearch() {
  const navigate = useNavigate();
  const mainLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      className={classes.mainLink}
      onClick={() => {
        if (link.label === "Create insights") {
          navigate("/");
        }
      }}
    >
      <div className={classes.mainLinkInner}>
        <link.icon size={20} className={classes.mainLinkIcon} />
        <Stack gap={2}>
          <span>{link.label}</span>
          {link.label === "Create insights" && (
            <Text size="xs" c={"dimmed"}>
              Powered by MageMetrics
            </Text>
          )}
        </Stack>
      </div>
      {link.notifications && (
        <Badge size="sm" variant="light" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ));

  const collectionLinks = collections.map((collection) => (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        if (collection.label === "Home") {
          navigate("/home");
        }
      }}
      key={collection.label}
      className={classes.collectionLink}
    >
      <Box component="span" mr={9} fz={16}>
        {collection.emoji}
      </Box>{" "}
      {collection.label}
    </a>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.section}>
        <Image py={"md"} w={"50%"} mx={"auto"} src={YourProductLogo} />
      </div>

      <TextInput
        placeholder="Search"
        size="xs"
        leftSection={<BiSearch size={12} />}
        rightSectionWidth={70}
        styles={{ section: { pointerEvents: "none" } }}
        mb="sm"
      />

      <div className={classes.section}>
        <div className={classes.mainLinks}>{mainLinks}</div>
      </div>

      <div className={classes.section} style={{ flex: 1 }}>
        <Group className={classes.collectionsHeader} justify="space-between">
          <Text size="xs" fw={500} c="dimmed">
            Collections
          </Text>
          <Tooltip label="Create collection" withArrow position="right">
            <ActionIcon variant="default" size={18}>
              <BiPlus size={12} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <div className={classes.collections}>{collectionLinks}</div>
      </div>
      <div className={classes.section}>
        <UserButton />
      </div>
    </nav>
  );
}
